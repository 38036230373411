@font-face {
  font-family: 'Roobert';
  font-weight: 400;
  src: local('Roobert'), url('../fonts/Roobert-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Roobert';
    font-weight: bold;
    src: local('Roobert'), url('../fonts/Roobert-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Roobert';
    font-weight: 600;
    src: local('Roobert'), url('../fonts/Roobert-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'Roobert';
  font-weight: 600;
  src: local('Roobert'), url('../fonts/Roobert-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Roobert';
  font-weight: 600;
  src: local('Roobert'), url('../fonts/Roobert-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Roobert';
  font-weight: 600;
  src: local('Roobert'), url('../fonts/Roobert-SemiBold.otf') format('opentype');
}

body {
  font-family: "Roobert", Arial, serif;
  height: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.2;
  background: linear-gradient(180deg, #1F2848 0%, #0B4374 50%, #1F2848 100%);
}
body #root {
  height: 100%;
}
body #root > div {
  height: 100%;
}
a,a:hover,button,button:hover,button:focus{cursor:pointer;outline:medium none!important;}
a.terms{text-decoration: underline;}

.valign-center {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  padding: 0;
}

.promo-pagination {
  justify-content: center;
  flex: 0 0 100%;
  max-width: 100%;
}

.promo-pagination li {
  display: flex;
  justify-content: center;
  margin: 0 5px;
  width: 23px;
  height: 23px;
}
.promo-pagination li a {
  border: none;
  color: #808080;
}

.promo-pagination li:first-child a {
  border: none;
}
.promo-pagination li:last-child a {
  border: none;
}

.promo-pagination li.active {
  background-color: #dbe3fe;
  border-radius: 50%;
  font-weight: 500;
}
.promo-pagination li.active a {
  color: #4c71fc;
}

h4, h5 {
  font-weight: bold;
}

header.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.active-promos{
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}


.container-promo {
  display: block;
  position: relative;
  padding: 20px 60px;
  border-radius: 20px;
  max-width: max-content;
  margin-top: -325px;
  margin-bottom: 30px;
  background: linear-gradient(180deg, #A8C2ED 0%, #4B7EB9 30%, #36699F 70%, #1F2848 100%);
}

@media screen and (max-width: 768px) {

  .active-promos{
    padding: 0;
    margin: 0;
  }

  .container {
    max-width: unset;
  }
  .no-mobile{
    display: none;
  }
  .action-icon {
    display: flex;
    justify-content: flex-end;
  }
  .hidden-sm{
    display: none;
  }

  .container-promo {
    margin-top: -35%;
  }
}

@media screen and (max-width: 576px) { 
  .container-promo {
    padding: 20px 10px;
    margin-top: 0px;
  }
  .fixed-bottom {
    bottom: 10px;
  } 
}

@media screen and (min-width: 768px) {

  .promo-pagination {
    margin-top: 10px;
  }
  .no-mobile{
    display: contents;
  }
  .hidden-md{
    display: none;
  }
  .container-promo {
    max-width: 650px;
    margin-top: -35%;
  }
}
@media screen and (min-width: 992px) {
  .promo-pagination li {
    width: 24px;
    height: 24px;
    margin: 0 6px;
  }

  .promo-pagination li a {
    font-size: 17px;
  }

  .promo-pagination li:first-child a {
    margin-top: 2px;
  }

  .promo-pagination li:last-child a {
    margin-top: 2px;
  }

  .hidden-lg{
    display: none;
  }

  .container-promo {
    max-width: 850px;
  }
}

body.ReactModal__Body--open .ReactModalPortal .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}

body.ReactModal__Body--open .ReactModalPortal .overlay .content {
  position: relative;
  outline: none;
  padding: 15px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: hidden;
  border-radius: 4px;
  max-width: 600px;
  width: 100%;
  height: 543px;
  text-align: center;
  z-index: 100;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1200px)  {
  .container-promo {
    max-width: 1000px;
  }
} 

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  body.ReactModal__Body--open .ReactModalPortal .overlay .content {
    max-width: 467px;
    height: 400px;
  }
  .container {
    max-width: 1000px;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1250px;
  }

  .container-promo {
    max-width: 1150px;
  }
}